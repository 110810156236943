import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import './style.css'
import Home from './views/home'
import UserHome from './views/user-home'
import NotFound from './views/not-found'

import config from "./views/config/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const App = () => {

  const [userData, setUserData] = useState("");
  const [cantShow, setCantShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const token = window.localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const response = await fetch(`${config.apiDomain}/api/user/logged/info`, {
          method: 'POST',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: config.requiredToken,
          },
          body: JSON.stringify({
            token: token,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();

        setUserData(data);
      } catch (error) {
        console.error('Fetch Data Error:', error);
        setCantShow(true);
        setLoading(false);
        toast.error('Unable to fetch user data at this time. Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    };

    if (window.localStorage.getItem("token")) {
      fetchData();
      intervalId = setInterval(fetchData, 30000); // Fetch data every 30 seconds

      return () => {
        clearInterval(intervalId);
      };
    }

    return () => { };
  }, []);

  async function checkToken() {
    try {
      const response = await fetch(`${config.apiDomain}/api/token-check`, {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: config.requiredToken,
        },
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });

      if (!response.ok) {
        throw new Error("failed to fetch!");
      }

      const data = await response.json();

      if (data.data === "token expired") {
        window.localStorage.clear();
        window.location.href = "/";
      } else if (data.status !== "active") {
        // Handle other cases when the token is not valid
        // For example, redirect to the login page or display an error message
        window.localStorage.clear();
        window.location.href = "/";
      } else if (data.error === "user doesn't exist") {
        window.location.href = "/";
        setTimeout(() => {
          toast.error(
            "Your account no longer exists. Please create an account and try again.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }, 1000);
        window.localStorage.clear();
      }
    } catch (error) {
      console.error(error);
      window.location.href = "/";
      toast.error("Unable to fetch user data at this time. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  async function checkTokenRepeat() {
    try {
      const response = await fetch(`${config.apiDomain}/api/token-check`, {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          token: config.requiredToken,
        },
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });

      if (!response.ok) {
        throw new Error("failed to fetch!");
      }

      const data = await response.json();

      if (data.data === "token expired") {
        window.localStorage.clear();
        window.location.href = "/";
      } else if (data.status !== "active") {
        // Handle other cases when the token is not valid
        // For example, redirect to the login page or display an error message
        window.localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.error(error);
      window.location.href = "/";
      toast.error("Unable to fetch user data at this time. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    setInterval(() => {
      checkToken();
    }, 10 * 1000);
  }

  return (
    <Router>
      <Switch>
        <Route exact component={Home} path="/" />
        <Route exact path='/user/home' render={() => { return checkTokenRepeat() ? <UserHome userData={userData} /> : <Home /> }} />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
