import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import config from "./config/config";

import './home.css'

const Home = (props) => {
  const [userId, setUserId] = useState("");
  const [otp, setOTP] = useState("");
  const [isLoading, setLoading] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  function handleOTPRequest(e) {
    e.preventDefault();
    console.log(userId)
    setLoading(true);
    fetch(`${config.apiDomain}/api/otp-send`, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: config.requiredToken,
      },
      body: JSON.stringify({
        userId,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch!");
        }
        return res.json();
      })
      .then((data) => {
        if (data.message === "OTP Request Email Sent") {
          toast.success("OTP Request Email Sent", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
          setShowOTP(true);
        } else if (data.error === "Invalid OTP") {
          toast.warn("Invalid OTP", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        } else {
          toast.error("User Not Found", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Unable to login at this time. Please try again | If you continue to see this message please contact the server administrator",
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setLoading(false);
        console.log(error);
      });
  }
  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    fetch(`${config.apiDomain}/api/login-user`, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        token: config.requiredToken,
      },
      body: JSON.stringify({
        OTP: otp,
        UserID: userId
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch!");
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === "ok") {
          toast.success("Login Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
          window.localStorage.setItem("token", data.data);
          window.localStorage.setItem("loggedIn", true);
          setLoading(false);
          setTimeout(() => {
            window.location.href = `${config.webDomain}/user/home`;
          }, 1500);
        } else if (data.error === "Invalid OTP") {
          toast.warn("Invalid OTP", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        } else {
          toast.error("User Not Found", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Unable to login at this time. Please try again | If you continue to see this message please contact the server administrator",
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setLoading(false);
        console.log(error);
      });
  }
  return (
    <div className="home-container">
      <Helmet>
        <title>Centennial Stadium Creds.</title>
        <meta property="og:title" content="Centennial Stadium Creds." />
      </Helmet>
      <header data-role="Accordion" className="home-header">
        <img
          src="/logo%20transparent-500w.png"
          alt="image"
          className="home-image"
        />
        <div className="home-separator"></div>
      </header>
      <div className="home-hero">
        <form className="home-form">
          <div className="home-container1">
            <h1 className="home-text">
              <span>OTP Login</span>
              <br></br>
            </h1>
            <span className="home-text3">User ID:</span>
            {showOTP ? null : (<input
              type="text"
              placeholder="Enter ID"
              required={true}
              onChange={(e) => setUserId(e.target.value)}
              className="home-textinput input"
            />)}
            {showOTP ? (<input
              type="text"
              placeholder={userId}
              required={true} onChange={(e) => setUserId(e.target.value)}
              className="home-textinput input"
              disabled={true}
            />) : null}
            {showOTP ? (<input
              type="text"
              placeholder="Enter OTP"
              required={true}
              onChange={(e) => setOTP(e.target.value)}
              className="home-textinput input"
            />) : null}
            {showOTP ? null : (<button
              type="submit"
              disabled={isLoading}
              className="home-button button"
              onClick={handleOTPRequest}
            >
              {isLoading ? "Sending OTP..." : "Send OTP"}
            </button>)}
            {showOTP ? (<button
              type="submit"
              disabled={isLoading}
              className="home-button button"
              onClick={handleSubmit}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>) : null}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Home
