import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import CredSec from '../components/cred-sec'
import './user-home.css'
import config from './config/config'

const UserHome = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setInterval(() => {
            fetch(`${config.apiDomain}/api/creds/all`, {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: config.requiredToken,
                }
            })
                .then(response => response.json())
                .then(data => setData(data))
                .catch(error => console.error('Error fetching data:', error));
        }, 500);
    }, []);

    console.log(data)
    return (
        <div className="user-home-container">
            <Helmet>
                <title>UserHome - Centennial Stadium Creds.</title>
                <meta
                    property="og:title"
                    content="UserHome - Centennial Stadium Creds."
                />
            </Helmet>
            <header data-role="Accordion" className="user-home-header">
                <img
                    alt="image"
                    src="/logo%20transparent-500w.png"
                    className="user-home-image"
                />
                <div className="user-home-separator"></div>
            </header>
            <div className="user-home-cred-container">
                {data.map(item => (
                    <div className="user-home-container1">
                        <CredSec
                            rootClassName="rootClassName3"
                            key={item.application}
                            app={item.application}
                            user={item.username}
                            pass={item.password}
                            when={item.when}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UserHome
